import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setLoading } from '../actions';

import GlobalNavigationBar from '../GlobalNavigationBar';
import '../CoreLayout/index.scss';

/**
 * This loading component used as a root wrapper for rendering empty page when we are loading a route.
 */

const RouteLoading = ({ setLoading, location }) => {
    useEffect(() => {
        setLoading(true);
        return () => {
            setLoading(false);
        };
    }, []);

    return (
        <div className='core-wrapper'>
            <GlobalNavigationBar activeTab={location.state} />
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    setLoading: on => dispatch(setLoading(on)),
});

export default withRouter(connect(null, mapDispatchToProps)(RouteLoading));
