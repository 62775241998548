import { API } from 'constants.js';
import { CALL_API } from 'middleware/api.js';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export function getProfile() {
    return {
        [CALL_API]: {
            endpoint: API.auth.profile,
            method: 'GET',
            types: [REQUEST, GET_PROFILE, FAILURE],
        }
    };
}

export function setProfile(profile) {
    /*
        profile: {
            user: { full_name: '' },
            contact_email: '',
            contact_number: '',
            staff_code: ''
        }
    */

    return {
        [CALL_API]: {
            endpoint: API.auth.profile,
            method: 'PUT',
            types: [REQUEST, SET_PROFILE, FAILURE],
            body: profile
        }
    };
}

export function changePassword(old_password, new_password) {
    return {
        [CALL_API]: {
            endpoint: API.auth.change,
            method: 'PUT',
            types: [REQUEST, CHANGE_PASSWORD, FAILURE],
            body: {
                old_password,
                new_password
            }
        }
    };
}
