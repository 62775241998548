import { Route, Switch } from 'react-router-dom';

import React, { lazy, Suspense } from 'react';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';

import 'styles/base.scss';

import DataLoading from 'components/layouts/LoadingViews/DataLoading';
import RouteLoading from 'components/layouts/LoadingViews/RouteLoading';

const CoreLayout = lazy(() => import('components/layouts/CoreLayout'));
const NotFound = lazy(() => import('components/layouts/NotFound'));
const Home = lazy(() => import('components/Home'));
const Map = lazy(() => import('components/Home'));
const History = lazy(() => import('components/Home'));
const Account = lazy(() => import('components/Account/index.js'));
const Settings = lazy(() => import('components/Home'));

const Login = lazy(() => import('components/Auth/Login'));
const Logout = lazy(() => import('components/Auth/Logout'));
const ForgotPassword = lazy(() => import('components/Auth/ForgotPassword'));
const SetPassword = lazy(() => import('components/Auth/SetPassword'));

const Routes = props => {
    const routes = {
        Home: {
            Home: {
                path: '/',
                component: Home,
                title: 'Home',
                privateRoute: true,
                menu: true
            }
        },
        Map: {
            Map: {
                path: '/map',
                component: Map,
                title: 'Map',
                privateRoute: true,
                menu: true
            }
        },
        History: {
            History: {
                path: '/history',
                component: History,
                title: 'History',
                privateRoute: true,
                menu: true
            }
        },
        Account: {
            Account: {
                path: '/account',
                component: Account,
                title: 'Account',
                privateRoute: true,
                menu: true
            }
        },
        Settings: {
            Settings: {
                path: '/settings',
                component: Settings,
                title: 'Settings',
                privateRoute: true,
                menu: true
            }
        },
        Other: {
            Login: {
                path: '/login',
                component: Login,
                title: 'Login',
                privateRoute: false,
                menu: false
            },
            Logout: {
                path: '/logout',
                component: Logout,
                title: 'Logout',
                privateRoute: true
            },
            ForgotPassword: {
                path: '/forgot',
                component: ForgotPassword,
                title: 'Forgot password',
                privateRoute: false,
                menu: false,
            },
            SetPassword: {
                path: '/reset',
                component: SetPassword,
                title: 'Set a password',
                privateRoute: false,
                menu: false,
            }
        }
    };

    return (
        <DataLoading show={props.Layout.loading}>
            <Suspense fallback={<RouteLoading />}>
                <Switch>
                    {_.flatten(_.map(routes, (navParent, parentKey) => (
                        _.map(navParent, (route, routeKey) => {
                            const { component, path, header, title, privateRoute, menu } = route;
                            return (
                                <Route
                                    exact
                                    path={path}
                                    key={routeKey}
                                    render={route => {
                                        if(privateRoute && !props.Auth.isAuthenticated) {
                                            return <Redirect to='/login' />;
                                        } else {
                                            return (
                                                <CoreLayout
                                                    component={component}
                                                    route={route}
                                                    header={header}
                                                    navParent={parentKey}
                                                    title={title}
                                                    menu={menu}
                                                    userMenu={_.pickBy(routes, { userMenu: true })}
                                                />
                                            );
                                        }
                                    }}
                                />
                            );

                        })
                    )))}

                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </DataLoading>
    );
};

const mapStateToProps = state => ({
    Auth: state.Auth,
    Layout: state.Layout
});

export default withRouter(connect(mapStateToProps)(Routes));
