import { GET_PROFILE } from 'components/Account/actions.js';

const initialState = {
    name: '',
    email: '',
    phone: '',
    staffCode: ''
};

export default function account(state = initialState, action) {
    switch(action.type) {
        case GET_PROFILE: {
            const { user, contact_email, contact_number, staff_code } = action.response;

            return {
                ...state,
                fullName: user.full_name,
                email: contact_email,
                phone: contact_number,
                staffCode: staff_code
            };
        }
        default: { return state; }
    }
}
