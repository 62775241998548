import moment from 'moment';
import { addLocale, useLocale as doUseLocale } from 'ttag';
import { BACKEND_DATETIME_FORMAT } from 'constants.js';

export const fetchLocale = () => (
    localStorage.getItem('locale') || (navigator.languages && navigator.languages[0]) || navigator.language || 'fi-FI');

export const changeLanguage = locale => {
    try {
        const translationsObj = require(`../i18n/${locale}.po.json`);
        addLocale(locale, translationsObj);
        setLocale(locale);
    } catch(e) {
        console.warn('could not load language: ', locale);
        setLocale('fi');
    }
};

const setLocale = lang => {
    localStorage.setItem('locale', lang);
    lang === 'zh' ? moment.locale('zh-cn') : moment.locale(lang);
    doUseLocale(lang);
};

export const formatter = new Intl.NumberFormat(fetchLocale(), {
    style: 'currency',
    currency: 'EUR'
});

export const formatDateTime = dateTime => {
    const currentLocale = fetchLocale();

    try {
        const newDate = new Date(dateTime);
        const date = new Intl.DateTimeFormat(currentLocale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).format(newDate);
        const time = new Intl.DateTimeFormat(currentLocale, {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }).format(newDate);
        const full = date.concat(' ', time);

        return {
            full,
            date,
            time
        };
    } catch(e) {
        console.error(e);
    }
};

export const isValidBackendTime = str => moment(str, BACKEND_DATETIME_FORMAT).isValid();

// Returns true if the color in {hexOrRGB} string is in hex or rgb format, and dark
export const isColorDark = hexOrRGB => {
    let rgb = hexOrRGB.trim(); // getComputedValue returns strings with whitespace......................................
    let r = null;
    let g = null;
    let b = null;

    if(rgb.charAt(0) === '#') {
        rgb = parseInt(rgb.substring(1), 16);
        /* eslint-disable no-bitwise */
        r = (rgb >> 16) & 0xff;
        g = (rgb >> 8) & 0xff;
        b = (rgb >> 0) & 0xff;
        /* eslint-enable no-bitwise */
    } else {
        const rgbArr = rgb.match(/\d+/g);
        /* eslint-disable prefer-destructuring */
        r = rgbArr[0];
        g = rgbArr[1];
        b = rgbArr[2];
        /* eslint-enable prefer-destructuring */
    }

    const luma = (0.2126 * r) + (0.7152 * g) + (0.0722 * b);

    return luma < 128;
};
