import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const SET_SUCCESS = 'SET_SUCCESS';

export function login(credentials) {
    return {
        [CALL_API]: {
            endpoint: API.auth.token,
            method: 'POST',
            types: [REQUEST, LOGIN_SUCCESS, FAILURE],
            showSuccess: false,
            body: {
                username: credentials.username,
                password: credentials.password
            }
        }
    };
}

export function logout() {
    return {
        type: 'LOGOUT'
    };
}

export function resetPassword(email) {
    return {
        [CALL_API]: {
            endpoint: API.auth.reset,
            method: 'PUT',
            types: [REQUEST, RESET_SUCCESS, FAILURE],
            body: { email }
        }
    };
}

export function setPassword(token, new_password) {
    return {
        [CALL_API]: {
            endpoint: API.auth.set.replace(':token', token),
            method: 'PUT',
            types: [REQUEST, SET_SUCCESS, FAILURE],
            body: { new_password }
        }
    };
}
