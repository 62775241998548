import { API } from 'constants.js';
import { CALL_API } from 'middleware/api.js';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const TEST = 'TEST';
export const TEST_API = 'TEST_API';

export function test(value) {
    return {
        type: TEST,
        test: value
    };
}

export function testApi() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.test,
            types: [REQUEST, TEST_API, FAILURE]
        }
    };
}
