import { t } from 'ttag';

export const ErrorMessages = () => ({
    GENERAL_ERROR: t`GENERAL_ERROR`,
    NETWORK_ERROR: t`NETWORK_ERROR`,
    USER_WAS_NOT_VERIFIED: t`USER_WAS_NOT_VERIFIED`,
    INCORRECT_CREDENTIALS: t`INCORRECT_CREDENTIALS`,
    EMAIL_TAKEN: t`EMAIL_TAKEN`,
    EMAIL_INVALID: t`EMAIL_INVALID`,
    USER_NOT_FOUND: t`USER_NOT_FOUND`,
    ROUTE_NOT_FOUND: t`ROUTE_NOT_FOUND`,
});

export const SuccessMessages = () => ({
    GENERAL_SUCCESS: t`GENERAL_SUCCESS`,
});
