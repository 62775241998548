import React from 'react';
import { t } from 'ttag';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { ReactComponent as DriveLogo } from 'static/vedia-drive-logo.svg';
import { ReactComponent as IconSettings } from 'static/Navigation/settings.svg';
import { ReactComponent as IconHome } from 'static/Navigation/home.svg';
import { ReactComponent as IconMap } from 'static/Navigation/map.svg';
import { ReactComponent as IconHistory } from 'static/Navigation/history.svg';
import { ReactComponent as IconAccount } from 'static/Navigation/account.svg';

import { ReactComponent as IconLogout } from 'static/Navigation/logout.svg';

import './index.scss';

const NavItem = ({ item, isLogo }) => {
    const { active, path, Icon, label, showMobile } = item;
    const itemClassName = (
        `${!showMobile ? 'hide-mobile ' : ''}${isLogo ? 'logo' : `nav-item ${active ? 'active' : ''}`}`
    );

    return (
        <Link
            to={{ pathname: path, state: label || undefined }}
            className={itemClassName}
        >
            <Icon />
            {label && <span>{label}</span>}
        </Link>
    );
};

const navTitles = activeTab => ({
    Logo: {
        path: '/',
        Icon: DriveLogo,
    },
    Home: {
        label: t`HOME`,
        path: '/',
        Icon: IconHome,
        active: activeTab === 'Home',
        showMobile: true
    },
    Map: {
        label: t`MAP`,
        path: '/map',
        Icon: IconMap,
        active: activeTab === 'Map',
        showMobile: true
    },
    History: {
        label: t`HISTORY`,
        path: '/history',
        Icon: IconHistory,
        active: activeTab === 'History',
        showMobile: true
    },
    Account: {
        label: t`ACCOUNT`,
        path: '/account',
        Icon: IconAccount,
        active: activeTab === 'Account',
        showMobile: true
    },
    Settings: {
        label: t`SETTINGS`,
        path: '/settings',
        Icon: IconSettings,
        active: activeTab === 'Settings',
    },
    Logout: {
        label: t`LOGOUT`,
        path: '/logout',
        Icon: IconLogout,
        active: false,
    },
});

const GlobalNavigationBar = ({ activeTab, history }) => {
    const items = navTitles(activeTab);

    return (
        <div className='global-navi-wrapper'>
            <div className='padder' />
            <nav className='global-navi'>
                {Object.keys(items).map((item, i) => (
                    <NavItem
                        key={i}
                        item={items[item]}
                        history={history}
                        isLogo={item === 'Logo'}
                    />
                ))}
            </nav>
        </div>
    );
};

export default withRouter(GlobalNavigationBar);
