import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createStore from './store/createStore';
import createServiceWorker from './sw/createSw';
import Routes from './Routes';
import { fetchLocale, changeLanguage } from './utils';

const locale = fetchLocale();
changeLanguage(locale.slice(0, 2));
const initialState = window.___INITIAL_STATE__;
const store = createStore(initialState);

createServiceWorker(store);

const App = () => (
    <Provider store={store}>
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    </Provider>
);

export { App };

const rootElement = document.getElementById('root');
if(rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}
