import {
    TEST,
    TEST_API
} from './actions.js';

const initialState = {
    test: 'Failure',
    testApi: {}
};

function home(state = initialState, action) {
    switch(action.type) {
        case TEST: {
            return {
                ...state,
                test: action.test
            };
        }
        case TEST_API: {
            return {
                ...state,
                testApi: action.response ? 'Success' : 'Failure, check connection to API'
            };
        }
        default: { return state; }
    }
}

export default home;
