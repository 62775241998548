import jwt from 'jsonwebtoken';

import {
    REQUEST,
    FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    CHANGE_PASSWORD,
} from './actions';

const initialState = {
    isAuthenticated: !!localStorage.getItem('access_token'),
    isLoading: false,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    error: null,
};

export default function auth(state = initialState, action) {
    switch(action.type) {
        case REQUEST: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.errorMessage
            };
        }
        case LOGIN_SUCCESS: {
            const data = action.response;
            const decoded = jwt.verify(data.access_token, data.public_key);
            localStorage.setItem('user', JSON.stringify(decoded.user));
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('public_key', data.public_key);

            return {
                ...state,
                isAuthenticated: true,
                user: decoded.user,
                isLoading: false,
                error: null,
            };
        }
        case LOGOUT: {
            localStorage.removeItem('user');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('public_key');

            return {
                ...state,
                error: action.data,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false,
            };
        }
        case CHANGE_PASSWORD: {
            const response = jwt.verify(action.response.access_token, action.response.public_key);

            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('access_token', action.response.access_token);
            localStorage.setItem('refresh_token', action.response.refresh_token);
            localStorage.setItem('public_key', action.response.public_key);

            return {
                ...state,
                success: 'Password has been updated succesfully',
                change: true
            };
        }
        default: {
            return state;
        }
    }
}
