const initialState = {
    serviceWorker: null,
    push: {
        public_key: null,
        available: false,
        enabled: false,
        manager: null,
        subscription: null
    }
};

function home(state = initialState, action) {
    switch(action.type) {
        case 'SW_PUSH_PUBLIC_KEY': {
            return {
                ...state,
                push: {
                    public_key: action.response.public_key
                }
            };
        }
        case 'SW_SET': {
            return {
                ...state,
                serviceWorker: action.serviceWorker
            };
        }

        case 'SW_PUSH_AVAILABLE': {
            localStorage.setItem('push_available', 'true');
            return {
                ...state,
                push: {
                    ...state.push,
                    available: true,
                    manager: action.manager
                }
            };
        }

        case 'SW_PUSH_UNAVAILABLE': {
            localStorage.setItem('push_available', 'false');
            return {
                ...state,
                push: {
                    ...state.push,
                    available: false
                }
            };
        }

        case 'SW_PUSH_ENABLED': {
            localStorage.setItem('push_enabled', 'true');
            return {
                ...state,
                push: {
                    ...state.push,
                    enabled: true,
                    subscription: action.subscription
                }
            };
        }

        case 'SW_PUSH_DISABLED': {
            localStorage.setItem('push_enabled', 'false');
            return {
                ...state,
                push: {
                    ...state.push,
                    enabled: false,
                    subscription: null
                }
            };
        }

        case 'SW_REGISTER_SUBSCRIPTION_SUCCESS':
        case 'SW_REGISTER_SUBSCRIPTION_REQUEST':
        case 'SW_REGISTER_SUBSCRIPTION_FAILURE':
        case 'SW_PUBLIC_KEY_REQUEST': {
            return {
                ...state
            };
        }

        default: {
            return state;
        }
    }
}

export default home;
