import { changeLanguage } from 'utils';

export const SET_LOADING = 'SET_LOADING';
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const SHOW_TOP_NOTIFICATION = 'SHOW_TOP_NOTIFICATION';
export const HIDE_TOP_NOTIFICATION = 'HIDE_TOP_NOTIFICATION';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const HIDE_NOTIFICATION_MODAL = 'HIDE_NOTIFICATION';
export const SHOW_NOTIFICATION_MODAL = 'SHOW_NOTIFICATION';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export function setLoading(on) {
    return {
        type: SET_LOADING,
        on
    };
}

export function hideNotification() {
    return {
        type: HIDE_TOP_NOTIFICATION
    };
}

export function toggleMenu() {
    return { type: TOGGLE_MENU };
}

export function hideNotificationModal() {
    return { type: HIDE_NOTIFICATION_MODAL };
}

export function showNotificationModal(header, body, onConfirm, confirmLabel) {
    return {
        type: SHOW_NOTIFICATION_MODAL,
        header,
        body,
        onConfirm,
        confirmLabel
    };
}

export function changeLocale(langCode) {
    changeLanguage(langCode);

    return {
        type: CHANGE_LOCALE,
        lang: langCode
    };
}
