import moment from 'moment';

/* eslint-disable */
export const SERVER = (process.env.NODE_ENV === 'production' && window.react.REACT_APP_API_URL !== '') ? window.react.REACT_APP_API_URL : process.env.REACT_APP_API_URL;

export const API = {
    auth: {
        key: `${SERVER}auth/key/`,
        token: `${SERVER}auth/`,
        refresh: `${SERVER}auth/refresh/`,
        logout: `${SERVER}auth/logout/`,
        reset: `${SERVER}user/password/reset/`,
        set: `${SERVER}user/password/set/:token/`,
        change: `${SERVER}user/password/`,
        profile: `${SERVER}user/recipient-profile/`
    },
    test: `${SERVER}auth/test/`,
    headers: {
        'Content-Type': 'application/json',
        'App-Version': '1.1.0',
        'Client-App': 'app'
    }
};

export const BACKEND_DATETIME_FORMAT = moment.ISO_8601;
