import {
    SET_LOADING,
    SHOW_TOP_NOTIFICATION,
    HIDE_TOP_NOTIFICATION,
    TOGGLE_MENU,
    SHOW_NOTIFICATION_MODAL,
    HIDE_NOTIFICATION_MODAL
} from './actions';

const initialState = {
    loading: false,
    topNotification: {
        text: null,
        type: null
    },
    showMenu: false,
    notificationModal: {
        open: false,
        header: '',
        body: '',
        onConfirm: null,
        confirmLabel: ''
    }
};

let resetTimeout;

export default function layout(state = initialState, action) {
    switch(action.type) {
        case SET_LOADING: {
            return {
                ...state,
                loading: action.on
            };
        }
        case SHOW_TOP_NOTIFICATION: {
            resetTimeout = setTimeout(() => {
                action.asyncDispatch({
                    type: 'HIDE_TOP_NOTIFICATION'
                });
            }, 4000);
            return {
                ...state,
                topNotification: {
                    text: action.text,
                    type: action.notificationType
                }
            };
        }
        case HIDE_TOP_NOTIFICATION: {
            resetTimeout && clearTimeout(resetTimeout);
            return {
                ...state,
                topNotification: initialState.topNotification
            };
        }
        case TOGGLE_MENU: {
            return {
                ...state,
                showMenu: !state.showMenu
            };
        }
        case SHOW_NOTIFICATION_MODAL: {
            return {
                ...state,
                notificationModal: {
                    open: true,
                    header: action.header,
                    body: action.body,
                    onConfirm: action.onConfirm,
                    confirmLabel: action.confirmLabel
                }
            };
        }
        case HIDE_NOTIFICATION_MODAL: {
            return {
                ...state,
                notificationModal: {
                    ...state.notificationModal,
                    open: false
                }
            };
        }
        default: {
            return state;
        }
    }
}
