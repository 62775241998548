import { combineReducers } from 'redux';
import Home from 'components/Home/reducers';
import Auth from 'components/Auth/reducers';
import Account from 'components/Account/reducers.js';
import Layout from 'components/layouts/reducers';
import ServiceWorker from 'sw/reducers';

const appReducer = combineReducers({
    Auth,
    Account,
    Home,
    Layout,
    ServiceWorker
});

const makeRootReducer = (state, action) => (
    action.type === 'LOGOUT'
        ? appReducer(undefined, action)
        : appReducer(state, action)
);

export default makeRootReducer;
