import axios from 'axios';
import { API } from '../constants';

import { ErrorMessages, SuccessMessages } from './messages';

function removeLocalStorageKeys() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('public_key');
    localStorage.removeItem('oauth2');
    localStorage.removeItem('user');
    window.location.reload();
}

/* eslint-disable */
export let isRefreshing = false;
export let apiQueue = [];


const processQueue = (error, token = null) => {
    apiQueue.forEach(prom => {
        if(error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    apiQueue = [];
};

axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    const oRequest = error.config;
    if(!error.response) {
        return Promise.reject(error);
    } else if(
        (error.response.status === 401 || error.response.status === 403) && // TODO: remove 403 check when backend has their shit together (we get 401 from REFRESH_TOKEN_EXPIRED)
        !oRequest.retry && error.response.data.error_key !== 'INCORRECT_CREDENTIALS') {
        if(['REFRESH_TOKEN_EXPIRED', 'REFRESH_TOKEN_DATA_INVALID'].includes(error.response.data.error_key)) {
            removeLocalStorageKeys();
            return Promise.reject();
        }

        if(isRefreshing) {
            return new Promise(function (resolve, reject) {
                apiQueue.push({ resolve, reject })
            }).then(token => {
                const oauth2 = localStorage.getItem('oauth2');
                if(oauth2) {
                    oRequest.headers.Authorization = 'OAuth2 ' + token;
                } else {
                    oRequest.headers.Authorization = 'Bearer ' + token;
                }
                return axios(oRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }

        oRequest.retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
            let data = {};
            const oauth2 = localStorage.getItem('oauth2');

            if(oauth2) {
                data = {
                    access_token: localStorage.getItem('access_token'),
                    oauth: localStorage.getItem('oauth2')
                }
            } else {
                data = {
                    refresh_token: localStorage.getItem('refresh_token')
                };
            }

            axios.post(API.auth.refresh, data, {
                    headers: API.headers
                })
                .then(tokenRefresh => {
                    const data = tokenRefresh.data;
                    localStorage.setItem('access_token', data.access_token);

                    if(oauth2) {
                        localStorage.setItem('user', JSON.stringify(data.user));
                        axios.defaults.headers.common.Authorization = 'OAuth2 ' + data.access_token;
                        oRequest.headers.Authorization = 'OAuth2 ' + data.access_token;
                    } else {
                        localStorage.setItem('refresh_token', data.refresh_token);
                        localStorage.setItem('public_key', data.public_key);
                        axios.defaults.headers.common.Authorization = 'Bearer ' + data.access_token;
                        oRequest.headers.Authorization = 'Bearer ' + data.access_token;
                    }
                    axios.defaults.headers.common.Authorization = 'Bearer ' + data.access_token;
                    oRequest.headers.Authorization = 'Bearer ' + data.access_token;
                    processQueue(null, data.access_token);
                    resolve(axios(oRequest));
                })
                .catch(tokenErr => {
                    processQueue(tokenErr, null);
                    reject(tokenErr);
                })
                .then(() => {
                    isRefreshing = false
                })
        })
    }

    return Promise.reject(error);
});

/* eslint-enable */

function callApi(endpoint, method, body) {
    const token = localStorage.getItem('access_token') || null;
    const oauth2 = localStorage.getItem('oauth2') || null;
    const { headers } = API;

    if(token) {
        if(token) {
            headers.Authorization = oauth2 ? `OAuth2 ${token}` : `Bearer ${token}`;
        } else {
            throw new Error('No token found in localStorage!');
        }
    }
    return axios({
        url: endpoint,
        headers,
        method,
        data: body
    }).then(response => Promise.resolve(response.data));
}

export const CALL_API = Symbol('CALL API');

export default store => next => action => {
    const callAPI = action[CALL_API];

    if(typeof callAPI === 'undefined') {
        return next(action);
    }

    const {
        endpoint, types, authenticated, method, body, showError, showSuccess, showLoading = true, ...rest
    } = callAPI;

    const [requestType, successType, errorType] = types;

    if(showLoading) {
        store.dispatch({ type: 'SET_LOADING', on: true });
    }
    store.dispatch({ type: requestType });

    return callApi(endpoint, method, body)
        .then(response => {
            if(showLoading) {
                store.dispatch({ type: 'SET_LOADING', on: false });
            }
            if(showSuccess || (showSuccess === undefined && ['PUT', 'POST', 'DELETE', 'PATCH'].includes(method))) {
                const successText = SuccessMessages()[response.success_key] || SuccessMessages().GENERAL_SUCCESS;
                store.dispatch({
                    type: 'SHOW_TOP_NOTIFICATION',
                    text: successText,
                    notificationType: 'success'
                });
            }
            next({
                response,
                authenticated,
                type: successType,
                ...rest
            });

            return response;
        })
        .catch(err => {
            store.dispatch({ type: 'SET_LOADING', on: false });
            let errorText = ErrorMessages().GENERAL_ERROR;
            if(!err.response) { // err.response is not defined when the user is offline
                errorText = ErrorMessages().NETWORK_ERROR;
                // err.response is undefined also when an error occurs in an action or reducer.
                // The error is silent (not outputted to console) because of the catch, so we do it here manually.
                console.warn(err);
            } else {
                const error = err.response.data;
                if(error) {
                    errorText = ErrorMessages()[error.error_key] || errorText;
                }
                if(err.details && err.details[0] && err.details[0].code === 'invalid') {
                    errorText = ErrorMessages().EMAIL_INVALID;
                }
            }
            if(showError || (showError === undefined && ['PUT', 'POST', 'DELETE', 'PATCH'].includes(method))) {
                store.dispatch({
                    type: 'SHOW_TOP_NOTIFICATION',
                    text: errorText,
                    notificationType: 'error'
                });
            }
            next({
                error: true,
                errorMessage: errorText,
                type: errorType,
                errorCode: err.response &&
                           err.response.status
            });

            return ({ error: true, errorMessage: errorText });
        });
};
